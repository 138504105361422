import request from '@/utils/request'
import {objectToQueryString} from '@/utils/utils';

//查询优惠券发行规则列表并分页
export function getCouponPublishRuleForPage(params) {
    return request.get('/couponController/getCouponPublishRuleForPage?' + objectToQueryString(params))
}

//添加发行规则
export function addCouponPublishRule(data) {
    return request.post('/couponController/addCouponPublishRule', data)
}

//下架发行规则
export function soldOutPublishRule(id) {
    return request.post('/couponController/soldOutPublishRule?id=' + id)
}

//根据id获取优惠券使用详情
export function getCouponUseInfo(id) {
    return request.get('/couponController/getCouponUseInfo?id=' + id)
}

//web下发优惠券
export function publishCoupon(data) {
    return request.post('/couponController/publishCoupon', data)
}

//获取优惠券使用列表
export function getCouponUseList(params) {
    return request.get('/couponController/getCouponUseList?' + objectToQueryString(params))
}

//获取事件并分页
export function getCouponEventForPage(page, size, eventId, isExport, exportSize) {
    return request.get('/couponController/getCouponEventForPage?page=' + page + '&size=' + size + '&eventId=' + eventId + '&isExport=' + isExport + '&exportSize=' + exportSize)
}

//根据手机号查询userID
export function getUsersByMobileNumAndType(mobile) {
    return request.get('couponController/getUsersByMobileNumAndType?mobile=' + mobile)
}

//根据车牌查询userID
export function getAutoInfoOrRegister(numplate, color) {
    return request.get('couponController/getAutoInfoOrRegister?numplate=' + numplate + '&color=' + color)
}

//查询发行优惠券详情
export function getCouponPublishRuleVO(id) {
    return request.get('/couponController/getCouponPublishRuleVO?id=' + id)
}



// 发行列表
export function getSendCouponList(params) {
    return request.post('/coupon/sendCouponList?' + objectToQueryString(params));
}

// 发行详情
export function getSendCouponInfo(params) {
    return request.post('/coupon/sendCouponInfo?' + objectToQueryString(params));
}

// 发行新增
export function addSendCoupon(params) {
    return request.post('/coupon/addSendCoupon?' + objectToQueryString(params));
}

// 管理发券
export function forSendCoupon(params) {
    return request.post('/coupon/sendCoupon',params);
}

// 发行下架
export function statusSendCoupon(params) {
    return request.post('/coupon/stopSendCoupon?' + objectToQueryString(params));
}


// 静态码下载
export function staticQRCodeDownLoad(params) {
    return request.post('/coupon/staticQRCodeDownLoad?' + objectToQueryString(params));
}

// 使用列表
export function getUseCouponList(params) {
    return request.post('/coupon/useCouponList?' + objectToQueryString(params));
}

// 使用详情
export function getUseCouponInfo(params) {
    return request.post('/coupon/useCouponInfo?' + objectToQueryString(params));
}